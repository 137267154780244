<template>
  <div class="majorItem">
    <div class="header">
      <div class="left">
        <h4>院校：{{ queryInfo.schoolName }}</h4>
        <h4>专业：{{ queryInfo.majorName }}</h4>
        <h4>
          预报名费：<span style="color:#ff6600">￥300.00</span
          >（考前辅导费）
        </h4>
      </div>
      <div class="right">
        <img src="https://file-static.cx-online.net/common/ndjy/code.jpg" alt="" />
      </div>
    </div>
    <el-divider></el-divider>
    <div class="body">
      <P><strong>培养目标：</strong></P>
      <P>{{ queryInfo.trainingGoal }}</P
      ><br />
      <P><strong>主要课程：</strong></P>
      <P>{{ queryInfo.mainCourse }}</P
      ><br />
      <P><strong>就业方向：</strong></P>
      <P>{{ queryInfo.workDirection }}</P>
    </div>
  </div>
</template>

<script>
import { majorInfo } from '../../api'
export default {
  data () {
    return {
      queryInfo: {
        id: this.$route.query.id,
        majorName: '',
        schoolName: '',
        trainingGoal: '',
        mainCourse: '',
        workDirection: ''
      }
    }
  },
  created () {
    this.getMajor()
  },
  methods: {
    getMajor () {
      majorInfo(this.queryInfo.id).then((res) => {
        this.queryInfo.majorName = res.data.data.majorName
        this.queryInfo.schoolName = res.data.data.schoolName
        this.queryInfo.trainingGoal = res.data.data.trainingGoal
        this.queryInfo.mainCourse = res.data.data.mainCourse
        this.queryInfo.workDirection = res.data.data.workDirection
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.majorItem {
  padding: 70px 0px;
  .header {
    display: flex;
    flex-direction: row;
    .left {
      flex: 1;
      h4 {
        font-size: 20px;
        line-height: 40px;
        text-align: left;
      }
    }
    .right {
      flex: 1;
      display: flex;
      flex-direction: row-reverse;
      img {
        width: 135px;
        height: 135px;
      }
    }
  }
  .body {
    line-height: 30px;
    font-size: 16px;
  }
}
</style>
